// ============================================================================
// BASE - Typography                                       Modified: 2020-06-10
// ============================================================================


// Headings
h1, .heading--1, h2, .heading--2, h3, .heading--3, h4, .heading--4, h5, .heading--5, h6, .heading--6 {
  line-height: $headings-line-height;
  margin-top: 0;
  margin-bottom: $base-spacing-unit--sm;
}

h1, .heading--1 {
  font-size: $h1-font-size;
  font-family: $h1-font-family;
  color: $h1-font-color;
  font-weight: $h1-font-weight;
}

h2, .heading--2 {
  font-size: $h2-font-size;
  font-family: $h2-font-family;
  color: $h2-font-color;
  font-weight: $h2-font-weight;
}

h3, .heading--3 {
  font-size: $h3-font-size;
  font-family: $h3-font-family;
  color: $h3-font-color;
  font-weight: $h3-font-weight;
}

h4, .heading--4 {
  font-size: $h4-font-size;
  font-family: $h4-font-family;
  color: $h4-font-color;
  font-weight: $h4-font-weight;
}

h5, .heading--5 {
  font-size: $h5-font-size;
  font-family: $h5-font-family;
  color: $h5-font-color;
  font-weight: $h5-font-weight;
}

h6, .heading--6 {
  font-size: $h6-font-size;
  font-family: $h6-font-family;
  color: $h6-font-color;
  font-weight: $h6-font-weight;
}

h1 + h2,
h1 + h3,
h1 + h4,
h1 + .ce_text {
  margin-top: 1.375rem; }

h2 + h3,
h2 + h4,
h2 + .ce_text {
  margin-top: .875rem; }

h3 + h4,
h3 + .ce_text {
  margin-top: .75rem; }

// Paragraphs, Lists, ...
ul, ol, dl,
blockquote, p, address,
hr,
fieldset, figure,
pre  {
  margin-top: 0;
  margin-bottom: $base-spacing-unit--sm;
}

p + h2,
ul + h2,
ol + h2 {
  margin-top: 1.625rem; }

p + h3,
ul + h3,
ol + h3 {
  margin-top: 1.5rem; }

p + h4,
ul + h4,
ol + h4 {
  margin-top: 1.125rem; }

ul, ol, dd {
  margin-left: $base-spacing-unit;
  list-style: disc; }

code, kbd, pre, samp {
  @include pre-default;
}

// Kein kursiv mehr!
i {
  font-style: normal;
}

// Images
figure {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

@supports (hyphens: auto) {
  body {
    hyphens: auto;
  }
}

// Simple Tab
.tab {
  &-adress em { display: inline-block; font-style: normal; width: 4.25rem; }
  &-dankemail em { display: inline-block; font-style: normal; width: 8rem; }
}
