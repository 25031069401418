// ============================================================================
// COMPONENTS - Forms                                      Modified: 2020-06-15
// ============================================================================

$forms-material-design:         false !default;
$label-show:                    true !default;
$label-color:                   $color-text !default;
$input-color:                   $color-text !default;
$input-color--background:       transparent !default;
$input-color--border:           $color-gray--600 !default;
$input-color--placeholder:      $color-gray--600 !default;
$submit-btn-width:              200px !default;
$submit-btn-margin-top:         0.75rem !default;   // 2020-06-15
$submit-btn-margin-bottom:      0 !default;


::-webkit-input-placeholder { color: $input-color--placeholder; }
:-ms-input-placeholder { color: $input-color--placeholder; }
::placeholder { color: $input-color--placeholder; }

@if $forms-material-design {

  @if $label-show {

    .widget-text label,
    .widget-textarea label {
      font-size: 80%;
      color: $input-color--placeholder;
      transition: color 1s;
      }

    .widget-text label.hide,
    .widget-textarea label.hide {
      color: transparent;
    }
    .widget-text label.active,
    .widget-textarea label.active {
      color: $color-brand;
    }

  } @else {

    .widget-text label,
    .widget-textarea label {
      display: none;
    }

  }

  input.text,
  textarea {
    display: block;
    width: 100%;
    font-family: $base-font-family;
    font-size: 1rem;
    line-height: 1.5;
    color: $input-color;
    background-color: $input-color--background;
    padding: 6px 8px 8px;
    margin-bottom: 15px;
    border-bottom: 1px solid;
    border-bottom-color: $input-color--border;
    transition: border-bottom-color 1s;
  }

  textarea {
    min-height: 150px;
    resize: vertical;
  }

  input.text:focus,
  textarea:focus {
    padding: 6px 8px 7px;
    border-bottom: 2px solid;
    border-bottom-color: $color-brand;
  }

} @else {

  .widget-captcha {
    background-color: rgba(255, 128 , 128, .5);
    padding: 1rem;
  }

  @if $label-show {

    .widget-text label,
    .widget-textarea label,
    .widget-captcha label {
      color: $label-color;
      display: inline-block;
      max-width: 100%;
      margin-bottom: $base-spacing-unit--xs;
    }

    .widget-captcha label {
      display: block;
    }

  } @else {

    .widget-text label,
    .widget-textarea label,
    .widget-captcha label {
      display: none;
    }

  }

  input.text,
  textarea,
  input.captcha {
    display: block;
    width: 100%;
    font-family: $base-font-family;
    font-size: 1rem;
    line-height: 1.5;
    padding: $base-spacing-unit--sm;
    border: 1px solid $input-color--border;
    @include border-radius($button-border-radius);
    background-color: $input-color--background;
    background-clip: padding-box;
    background-image: none;
  }

  textarea {
    min-height: 150px;
    resize: vertical;
  }

  span.captcha_text {
    display: inline-block;
  }

  input.captcha {
    float: right;
    width: 50px;
    text-align: center;
    margin-top: -1rem;
    background-color: #fff;
  }


  .widget-text,
  .widget-textarea,
  .widget-captcha {
    margin-bottom: $base-spacing-unit;
  }

  span.mandatory {
    color: $color-brand;
  }

  p.error {
    margin-bottom: 0;
  }

}

input.submit,
button.submit {
  @extend %button;
  @extend button.btn--primary;
  font-size: 1rem;
  min-width: $submit-btn-width;
  margin-top: $submit-btn-margin-top;
  margin-bottom: $submit-btn-margin-bottom;
}

button.submit {
  cursor: pointer;
}