// ============================================================================
// COMPONENTS - Hero                                       Modified: 2020-05-28
//
// Voraussetzung: euf_hero (Erdmann & Freunde)
// ============================================================================

$hero-height:                         600px !default;
$hero-height--phone:                  90vh !default;
$hero-breakpoint:                     $grid-breakpoint--lg !default;
$hero-image-brightness:               100% !default;
$hero-content-width--mobile:          80% !default;
$hero-content-width--desktop:         60% !default;
$hero-content-maxwidth--desktop:      600px !default;
$hero-content-padding--mobile:        $base-spacing-unit !default;
$hero-content-padding--desktop:       ($base-spacing-unit * 2) !default;
$hero-content-margin--desktop:        0 !default;
$hero-content-align--vertical:        center !default;      // flex-start, center, flex-end
$hero-content-align--horizontal:      flex-start !default;  // flex-start, center, flex-end
$hero-content-color:                  $color-white !default;
$hero-content-background:             transparent !default;
$hero-content-textalign:              center !default;      // left, center, right
$hero-button-topmargin:               $base-spacing-unit !default;
$hero-button-spacebetween:            $base-spacing-unit !default;
$hero-button-align:                   center !default;      // left, center, right


.mod_article.hero {
  max-width: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: $hero-height;
  position: relative;
  padding: 0;
  z-index: 0
}

.ce_hero {
 .image_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1
  }

  img {
    filter: brightness($hero-image-brightness);
    height: 100%;
    width: 100%;
    object-fit: cover
  }
}

.content {
  &_container {
    text-align: $hero-content-textalign;
    margin: 0 auto;
    min-height: $hero-height;
    display:flex;
    flex: 1;
    align-items: center;
    justify-content: center;
   }

  &_teaser {
    color: $hero-content-color;
    background-color: $hero-content-background;
    width: $hero-content-width--mobile;
    padding: $hero-content-padding--mobile;

    img {
      filter: none;
    }

  }

  &_button {
    margin-top: $hero-button-topmargin;
    text-align: $hero-button-align;
    & > a:nth-child(2) {
      margin-left: $hero-button-spacebetween;
    }
  }
}


@include media-breakpoint-portrait-phone{
  .mod_article.hero,
  .content_container {
    min-height: $hero-height--phone;
  }

  .content_teaser {
    width: 80%;
  }
}


@media screen and (min-width: #{$hero-breakpoint}) {
  .content {
    &_container {
      align-items: $hero-content-align--vertical;
      justify-content: $hero-content-align--horizontal;
    }

    &_teaser {
    width: $hero-content-width--desktop;
    max-width: $hero-content-maxwidth--desktop;
    padding: $hero-content-padding--desktop;
    margin: $hero-content-margin--desktop;
    }
  }
}
