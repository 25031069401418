// ============================================================================
// MIXINS - Responsive                                     Modified: 2020-05-20
// ============================================================================


@mixin media-breakpoint-up-sm{
  @media (min-width: #{$grid-breakpoint--sm}) {
    @content;
  }
}

@mixin media-breakpoint-up-md{
  @media (min-width: #{$grid-breakpoint--md}) {
    @content;
  }
}

@mixin media-breakpoint-up-lg{
  @media (min-width: #{$grid-breakpoint--lg}) {
    @content;
  }
}

@mixin media-breakpoint-up-xl{
  @media (min-width: #{$grid-breakpoint--xl}) {
    @content;
  }
}

@mixin media-breakpoint-up-hd{
  @media (min-width: #{$grid-breakpoint--hd}) {
    @content;
  }
}

@mixin media-breakpoint-down-sm{
  @media (max-width: #{$grid-breakpoint--sm - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-down-md{
  @media (max-width: #{$grid-breakpoint--md - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-down-lg{
  @media (max-width: #{$grid-breakpoint--lg - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-down-xl{
  @media (max-width: #{$grid-breakpoint--xl - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-down-hd{
  @media (max-width: #{$grid-breakpoint--hd - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-between-xs-sm {
  @media (min-width: 0) and (max-width: #{$grid-breakpoint--sm - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-between-sm-md {
  @media (min-width: #{$grid-breakpoint--sm}) and (max-width: #{$grid-breakpoint--md - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-between-md-lg {
  @media (min-width: #{$grid-breakpoint--md}) and (max-width: #{$grid-breakpoint--lg - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-between-lg-xl {
  @media (min-width: #{$grid-breakpoint--lg}) and (max-width: #{$grid-breakpoint--xl - 0.0625em}) {
    @content;
  }
}

@mixin media-breakpoint-landscape-phone {
  @media (max-width: #{$grid-breakpoint--md - 0.0625em}) and (orientation : landscape) {
    @content;
  }
}

@mixin media-breakpoint-portrait-phone {
  @media (max-width: #{$grid-breakpoint--md - 0.0625em}) and (orientation : portrait) {
    @content;
  }
}

@mixin media-breakpoint-phone {
  @media (max-width: #{$grid-breakpoint--md - 0.0625em}), (max-height: #{$grid-breakpoint--md - 0.0625em}) {
    @content;
  }
}
