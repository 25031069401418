// ============================================================================
// COMPONENTS - Cookiebar                                  Modified: 2020-06-13
// ============================================================================
//
//  1. Cookiebar (with tags)
//  2. Cookiebar (without tags)
//  3. iFrame-ePrivacy

$cms-tags-color-text:                     $color-text !default;
$cms-tags-background:                     $page-background-color !default;
$cms-tags-color-overlay:                  $color-overlay !default
$cms-tags-color-button:                   $color-brand !default;
$cms-tags-color-switch:                   $color-gray--500 !default;
$cms-tags-color-switch--checked:          $color-brand !default;
$cms-tags-color-switch--checked-disable:  lighten($color-brand,30%);
$cms-tags-border-color:                   $color-gray--500 !default
$cms-tags-border-width:                   $base-border-width !default;

$cms-cookie-color-text:                   $page-text-color--inverted !default;
$cms-cookie-background:                   $page-footer-background-color !default;
$cms-cookie-color-button:                 $color-brand !default;

$cms-optin-fallback-color-text:           $color-text !default;
$cms-optin-fallback-background:           $page-background-color !default;
$cms-optin-fallback-border-color:         $color-gray--500 !default
$cms-optin-fallback-border-width:         $base-border-width !default;


// 1. Cookiebar (with tags)
// ============================================================================
.mod_cms_accept_tags {
  font-family: Arial, Helvetica, Sans-serif;
  font-size: 14px;
  line-height: 1.33;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: $cms-tags-color-overlay;
  color: $cms-tags-color-text;

  button.submit {
    min-width: 150px;
  }

  button[value="accept"] {
    background-color: $cms-tags-color-button;
  }

  button[value="reject"] {
    border-color: $cms-tags-color-button;
    color: $cms-tags-color-text;
    background-color: transparent;
  }

  button[value="accept"].first {
    border-color: $cms-tags-color-button;
    color: $cms-tags-color-button;
    background-color: transparent;
  }

  .formbody {
    .widget-submit {
      @include media-breakpoint-down-sm {
        text-align: center;
        margin-bottom: $base-spacing-unit;
      }
      text-align: right;
    }
  }

  form {
    display: block;
    max-width: 800px;
    max-height: 90vh;
    margin: $base-spacing-unit;
    border: $cms-tags-border-width solid $cms-tags-border-color;
    @include border-radius($base-border-radius);
    @include box-shadow($color-box-shadow);
    background: $cms-tags-background;
    @include media-breakpoint-phone {
      overflow-y: scroll;
    }

    >.formbody {
      padding: $base-spacing-unit;
    }

    >.tags {
      display: flex;
      flex-wrap: wrap;

      >div {
        flex-basis: 100%;
        padding: 0 $base-spacing-unit $base-spacing-unit $base-spacing-unit;
        @include media-breakpoint-up-sm {
          flex-basis: 0;
          flex-grow: 1;
          padding: $base-spacing-unit;
        }

        .head {
          display: flex;
          justify-content: space-between;
          font-weight: 700;
          margin-top: 0;
          margin-bottom: $base-spacing-unit--sm;

          input {
            display: none;

            &:checked {
              &+label {
                background: $cms-tags-color-switch--checked;

                &:after {
                  background: White;
                  border-color: $cms-tags-color-switch--checked;
                  left: 100%;
                  transform: translateX(-100%);
                }
              }
            }
          }

          label {
            display: inline-block;
            flex-shrink: 0;
            position: relative;
            background: $cms-tags-color-switch;
            width: 44px;
            height: 20px;
            cursor: pointer;
            margin-left: 10px;
            @if $enable-rounded {
              border-radius: 17px;
            }

            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background: White;
              border: 3px solid $cms-tags-color-switch;
              @if $enable-rounded {
                border-radius: 50%;
              }
              transition: all .3s;
            }

            &.checked-disable {
              background: $cms-tags-color-switch--checked-disable;
              cursor: default;

              &:after {
                background: White;
                border-color: $cms-tags-color-switch--checked-disable;
                left: 100%;
                transform: translateX(-100%);
              }
            }

          }
        }

        p {
          font-size: 12px;
        }
      }
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid transparent;
      background-color: #ccc;
    }

    &::-webkit-scrollbar-track {
      background-color: lighten(#ccc, 13%);
      border-radius: 8px;
    }
  }
}


// 2. Cookiebar (without tags)
// ============================================================================
.mod_cms_cookie_bar {
  font-family: Arial, Helvetica, Sans-serif;
  font-size: 1rem;
  line-height: 1.4375;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  color: $cms-cookie-color-text;
  background: $cms-cookie-background;

  button.submit {
    min-width: 160px;
  }

  button[value="accept"] {
    background-color: $cms-cookie-color-button;
  }

  button[value="reject"] {
    border-color: $cms-cookie-color-button;
    color: $cms-cookie-color-button;
    background-color: transparent;
  }

  button[value="accept"].first {
    border-color: $cms-cookie-color-button;
    color: $cms-cookie-color-button;
    background-color: transparent;
  }

  form {
    display: block;
    max-width: 800px;
    margin: 0 auto;

    .formbody {
      margin: $base-spacing-unit;

      .widget-submit {
        text-align: center;
        @include media-breakpoint-up-md {
          text-align: right;
        }
      }
    }
  }
}


// 3. Externe Medien
// ============================================================================
.ce_optin_fallback {
  max-width: 480px;
  color: $cms-optin-fallback-color-text;
  background-color: $cms-optin-fallback-background;
  border: $cms-optin-fallback-border-width solid $cms-optin-fallback-border-color;
  @include border-radius($base-border-radius);
  @include box-shadow($color-box-shadow);
  padding: $base-spacing-unit;
  text-align: center;
  margin: $base-spacing-unit;

  a.btn {
    margin: ($base-spacing-unit * 0.5) 0;
  }
}

.cms-privacy-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;

  &__map {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

