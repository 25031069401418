// ============================================================================
// Website - Styles
// ============================================================================


// Grid
// ============================================================================

// Extra small devices (portrait phones, less than 576px)
// Max container width: auto
// Class prefix: .col-xs-
// ============================================================================

@media screen and (min-width: 0) {
  .col-xs-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .align-start { margin-left: 0; }
}


// Small devices (landscape phones, 576px and up)
// Max container width: 540px
// Class prefix: .col-sm-
// ============================================================================

@include media-breakpoint-up-sm {
}


// Medium devices (tablets, 768px and up)
// Max container width: 720px
// Class prefix: .col-md-
// ============================================================================

@include media-breakpoint-up-md {
  .col-md-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .align-start { margin-left: 25%; }
}


// Large devices (desktops, 992px and up)
// Max container width: 960px
// Class prefix: .col-lg-
// ============================================================================

@include media-breakpoint-up-lg {
  .col-lg-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-lg-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  .align-start { margin-left: 0}
}


//
// Extra large devices (large desktops, 1200px and up)
// Max container width: 1140px
// Class prefix: .col-xl-
// ============================================================================

@include media-breakpoint-up-xl {
  .col-xl-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-xl-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
}


//
// HD devices (large desktops, 1400px and up)
// Max container width: 1140px
// ============================================================================

@include media-breakpoint-up-hd{
}

.klaerung h1, .klaerung h2, .klaerung h3, .klaerung p {
  color: #FF0000 !important;
}



// Typography
// ============================================================================
h1, h2, h3, .heading--2 {
  font-weight: bold;
}

h2.onepage-heading {
  font-size: (1rem * 1.25);
  margin-bottom: 0;
}

h3.onepage-heading {
  font-size: $h1-font-size;
  margin-top: 0;
  margin-bottom: 2rem;
}

h3.onepage-subheading {
  font-size: $h2-font-size;
  margin-top: 0;
  margin-bottom: 2rem;
}


// Hero
// ============================================================================
[id="hero"] {
  .btn--primary {
    border-color: rgb(255, 255, 255);

    &:hover {
      border-color: rgb(255, 255, 255);
    }
  }
}


// Startseite
// ============================================================================
[id="startseite"] {
  margin-top: 1.5rem;

  h1 {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-down-md {
    .ce_text.first {
      display: flex;
      flex-direction: column;
    }

    .ce_text.first > p {
      order: -1;
    }

    .promotion {
      width: 100%;
      margin: 0.5rem auto;
    }
  }

  @include media-breakpoint-up-md {
    margin-top: 3rem;

    .promotion {
      float: right;
      width: 50%;
      padding-left: 1rem;
      margin-top: .5rem;
      margin-left: 1rem;

      @include media-breakpoint-up-xl {
        margin-top: -3rem;
      }
    }
  }

  .promotion video {
    width: 100%;
    height: auto;
    border: 1px solid $custom-color--2;
  }
}


// Leistungen
// ============================================================================
[id="leistungen"] {
  .ce_text.last.block {
    margin-top: 1.25rem;
  }

  @include media-breakpoint-down-md {
    .ce_text_list {
      margin-top: -1rem;
    }
    .ce_text_list_begin {
      margin-top: -1rem;
    }
  }

  @include media-breakpoint-up-md {
     .ce_text_list {
       margin-top: 1rem;
     }
   }
}


// Ueber mich
// ============================================================================
[id="ueber-mich"] {
  .ueber-mich-vita {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
  }

  .ueber-mich-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }
}


// Kontakt
// ============================================================================
[id="kontakt"] {
  background-color: $custom-color--3;
  color: $custom-color--1;
  padding-top: 3.5rem;
  padding-bottom: 4rem;

  @include media-breakpoint-down-md {
    padding-top: 2.5rem;
    padding-bottom: 3.0rem;
    div.tab-adress {
      margin-bottom: 3rem;
    }
  }

  h2 {
    color: $custom-color--5;
  }

  a {
    color: $custom-color--1;
    border-bottom: 1px dotted $custom-color--1;

    &:not(.btn):hover {
      color: $color-links;
      border-bottom: 1px solid $color-links;
    }
  }

}


// Google Maps
// ============================================================================
[id="google-maps"] {
  height: 395px;

  &.cms-privacy-iframe__map {
    @include media-breakpoint-down-md {
      background-image: url("../img/splash_map_small.jpg");
    }
    @include media-breakpoint-up-md {
      background-image: url("../img/splash_map.jpg");
    }
    background-size: cover;
  }
}


// Footer - Navbar
// ============================================================================
[id="footer-navbar"] {
  padding-top: $base-spacing-unit;
  padding-bottom: $base-spacing-unit
}
.nav-meta a {
  color: $page-footer-text-color;
}
//  .nav--meta {
//    float: right;
//  }
.footer-navbar-note {
  float: right;
  // float: left;
  a {
    color: $page-footer-navbar-text-color;
    border-bottom: 1px dotted $page-footer-navbar-text-color;

    &:hover {
      color: $color-links;
      border-bottom: 1px solid $color-links;
    }
  }
}

@include media-breakpoint-down-md {
  [id="footer-navbar"] {
    text-align: center;
  }
  //  .nav--meta {
  //    float: none;
  //  }
  .footer-navbar-note {
    float: none;
    padding-top: ($base-spacing-unit / 3);
  }
}

