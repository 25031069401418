// ============================================================================
// COMPONENTS - Misc                                       Modified: 2020-06-11
// ============================================================================
//
//  1. Error - 403/404
//  2.
//  3.


// 1. Error - 403/404
// ============================================================================

.error-403, .error-404 {
  #main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error {
    text-align: center;
    max-width: 480px;
    margin: 1rem;
    @include media-breakpoint-up-lg {
      max-width: 720px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      height: 100%;
    }

    &-icon {
      h1 {
        color: $color-brand;
        font-size: 72px;
        font-weight: bold;
        letter-spacing: 12px;
        padding-bottom: 2rem;
        @include media-breakpoint-up-lg {
          width: 150px;
          text-align: left;
          font-size: 144px;
        }
      }
    }

    &-message {
      h2 {
        color: $page-text-color;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 2px;
        @include media-breakpoint-up-lg {
          font-size: 32px;
        }
      }

      p {
        padding-bottom: 2rem;
      }
    }
  }
}


//
// ============================================================================

