// ============================================================================
// Website Variables
// ============================================================================
//
//   1. Google Fonts
//   2. General
//   3. Navbar
//   4. Sidenav
//   5. Dropdown
//   6. Onepagenav
//   7. Buttons
//   8. Toplink
//   9. Hero
//  10. Forms


// General
// ============================================================================

// Custom-Colors
$custom-color--1:                 #ffffff;
$custom-color--2:                 #333333;
$custom-color--3:                 #666666;
$custom-color--4:                 #ececec;
$custom-color--5:                 #02bf96;
$custom-color--6:                 #ff0080;
$custom-color--7:                 #000000;
$custom-color--8:                 #000000;
$custom-color--9:                 #000000;
$custom-color--10:                #000000;

// Colors
$color-default:                   $custom-color--3;
$color-brand:                     $custom-color--5;
$color-links:                     $custom-color--5;

// Page
$page-text-color:                 $custom-color--3;

// Base Font Settings
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v17-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans-v17-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-condensed-300 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
       url('../fonts/open-sans-condensed-v14-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans-condensed-v14-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


$base-font-family:                'Open Sans', sans-serif;
$base-weight-normal:              400;
$font-icon-small:                 true;

$base-font-size:                  1.0625rem;
$base-font-size--xs:              1rem;
$base-font-size--hd:              1.1875rem;

$base-line-height:                1.625;
$base-line-height--xs:            1.5;
$base-line-height--hd:            1.75;

// Base Spacing Units

// Base Border Settings

// Heading Font Setting
$headings-font-color:             $custom-color--5;
$h1-font-family:                  'Open Sans Condensed', sans-serif;
$h1-font-color:                   $headings-font-color;
$h1-font-weight:                  300;
$h2-font-family:                  'Open Sans Condensed', sans-serif;
$h2-font-color:                   $headings-font-color;
$h2-font-weight:                  300;
$h3-font-family:                  'Open Sans Condensed', sans-serif;
$h3-font-color:                   $headings-font-color;
$h3-font-weight:                  300;
$h4-font-family:                  'Open Sans', sans-serif;
$h4-font-weight:                  700;

$h1-font-size:                    (2.25rem * 1.25);
$h2-font-size:                    (1.75rem * 1.25);
$h3-font-size:                    (1.5rem * 1.25);

// Responsive Breakpoints

// Grid columns

// Grid containers

// Options
$enable-shadows:                  false;
$enable-rounded:                  true;

// Layout
// ============================================================================
$page-footer-navbar-background-color:  $custom-color--2;

// Hero
// ============================================================================
$hero-content-width--mobile:      70%;
$hero-content-width--desktop:     50%;
$hero-button-topmargin:           3rem;
$hero-image-brightness:           80%;

// Form
// ============================================================================
$label-show:                      false;
$forms-material-design:           true;
$input-color--placeholder:        $custom-color--3;
$input-color--background:         $custom-color--4;
$input-color--border:             transparent;
$input-color:                     $custom-color--2;

// CMS
// ============================================================================
$cms-tags-border-color:           $custom-color--5;
$cms-tags-border-width:           $base-spacing-unit--sm;
