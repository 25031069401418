// ============================================================================
// MIXINS - General                                        Modified: Modified: 2020-06-10
// ============================================================================


// Padding-default
// ============================================================================

@mixin padding-default {
  padding-left:  $grid-gutter--half;
  padding-right: $grid-gutter--half;
}


// Margin-auto
// ============================================================================

@mixin margin-auto {
  margin-left: auto;
  margin-right: auto;
}


// Basic styles for lists used as navs
// ============================================================================

@mixin navigation {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
}


// Pre-default
// ============================================================================

@mixin pre-default {
  font-family: $base-font-mono-family;
  color: $color-gray--800;
  background: $color-gray--200;
  overflow-x: scroll;
  padding: $base-spacing-unit;
}

// Set text-decoration
@mixin set-text-decoration() {

}


// General mixing for screens
// ============================================================================

@mixin mq($args...) {
  $media-type: 'only screen';
  $media-type-key: 'media-type';
  $args: keywords($args);
  $expr: '';

  @if map-has-key($args, $media-type-key) {
    $media-type: map-get($args, $media-type-key);
    $args: map-remove($args, $media-type-key);
  }

  @each $key, $value in $args {
    @if $value {
      $expr: "#{$expr} and (#{$key}: #{$value})";
    }
  }

  @media #{$media-type} #{$expr} {
    @content;
  }
}


@mixin screen-mobile-portrait($max, $orientation: false) {
  @include mq($max-width: $max, $orientation: $orientation) {
    @content;
  }
}
