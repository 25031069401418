// ============================================================================
// BASE - Layout                                           Modified: 2020-05-19
// ============================================================================


// Overlay
[id="overlay"] {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1010;
  display: none;
}

// Wrapper
[id="wrapper"] {
  min-width: $page-min-width;
  margin: 0 auto;
  padding: 0;
}

// Header
[id="header"] {
  color: $page-header-text-color;
  background-color: $page-header-background-color;
}

// Header-Infobar
[id="header-infobar"] {
  color: $page-header-infobar-text-color;
  background-color: $page-header-infobar-background-color;
}

// Header-Navbar
[id="header-navbar"] {
  color: $page-header-navbar-text-color;
  background-color: $page-header-navbar-background-color;
}

// Main

// Footer
[id="footer"] {
  color: $page-footer-text-color;
  background-color: $page-footer-background-color;
}

// Footer-Infobar
[id="footer-infobar"] {
  color: $page-footer-infobar-text-color;
  background-color: $page-footer-infobar-background-color;
}

// Footer-Navbar
[id="footer-navbar"] {
  color: $page-footer-navbar-text-color;
  background-color: $page-footer-navbar-background-color;
}

// Mod_article
.mod_article {
  padding-top: $base-spacing-unit--lg;
  padding-bottom: $base-spacing-unit--lg;
  position: relative;
}

.article-full-width {
  padding-top: 0;
  padding-bottom: 0;
}

.article-full-width p:last-child {
  margin-bottom: 0;
}